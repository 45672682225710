import React from "react"
import type { NextPage } from "next"
import Head from "next/head"
import { SignInOrSignUpView } from "@/views/SignInOrSignUpView"
import { AuthAction, withAuthUser, withAuthUserSSR } from "next-firebase-auth"

const LoginPage: NextPage = () => {
  return (
    <div>
      <Head>
        <title>Login</title>
        <meta name="description" content="Login to Hylite" />
        <link rel="icon" href="/hylite_favicon.png" />
      </Head>
      <main>
        <SignInOrSignUpView signUpMode={false} />
      </main>
    </div>
  )
}

export const getServerSideProps = withAuthUserSSR({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthed: AuthAction.RENDER,
})()

export default withAuthUser({
  whenAuthed: AuthAction.REDIRECT_TO_APP,
  whenUnauthedAfterInit: AuthAction.RENDER,
})(LoginPage)
